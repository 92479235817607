<template>
  <div class="recovery-code-form">
    <h2
      class="recovery-code-form__heading u-typography-gilroy u-text-display  u-text-display--m u-text-display--bold"
    >
      {{ $t('dashboard.label.recoveryCode') }}
    </h2>
    <p class="recovery-code-form__info u-typography-gilroy u-text u-text--m">
      {{ $t('dashboard.authentication.enterOneofYour') }}
    </p>
    <u-form ref="authenticationForm" class="form" @submit.prevent="onSubmit">
      <u-input
        v-model="recoveryCode"
        type="text"
        :label="$t('dashboard.label.enterCode')"
        :validators="[validateCodeField]"
      />
      <u-button class="form__primary-button" type="submit" :loading="loading">
        {{ $t('dashboard.action.confirm') }}
      </u-button>

      <div
        v-if="authenticationError"
        class="form__error-message u-typography-gilroy u-text u-text--xs"
      >
        <u-icon name="alert-triangle" />
        {{ authenticationError }}
      </div>

      <div
        class="support-message recovery-code-form__support-message u-typography-gilroy u-text u-text--s"
      >
        <div class="support-message__paragraph">
          {{ $t('dashboard.landing.havingProblems') }}
        </div>
        <div class="support-message__paragraph">
          {{ $t('dashboard.landing.contactSupportBy') }}
          <a
            class="support-message__link text-underlined u-typography-gilroy u-text u-text--s u-text--link"
            href="mailto:info@universkin.com"
            >info@universkin.com</a
          >
        </div>
      </div>
    </u-form>
  </div>
</template>

<script>
import { UButton, UForm, UInput, UIcon } from 'universkin-shared';

import { getRequiredValidator } from 'universkin-shared/src/utils/validators';

export default {
  name: 'RecoveryCodeForm',
  components: { UButton, UForm, UInput, UIcon },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    authenticationError: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      recoveryCode: '',
      validateCodeField: getRequiredValidator(this.$t('dashboard.authentication.filedRequired'))
    };
  },
  methods: {
    onSubmit() {
      this.$emit('submit-code', this.recoveryCode);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';
@import '~universkin-shared/src/assets/styles/scss/sizes';

.recovery-code-form {
  &__heading {
    margin: 0 0 10px 0;
  }

  &__info {
    color: $color-grey600;
    margin: 0 0 44px 0;
  }

  &__support-message {
    color: $color-grey600;
    margin-top: 20px;
  }
}

.form {
  display: flex;
  flex-direction: column;

  &__primary-button {
    margin-top: 24px;
  }

  &__error-message {
    color: $color-error;
    --u-icon-color: #{$color-error};
    margin-top: 5px;
    text-align: center;
  }
}

.support-message {
  &__paragraph:first-child {
    width: 100%;
    margin-bottom: 0;
  }

  &__link {
    color: $color-graphite;
  }
}

.text-underlined {
  text-decoration: underline;
}

@media (min-width: 500px) and (max-width: 599px) {
  .recovery-code-form {
    padding: 0 30px;
  }
}

@media (min-width: $medium-start) {
  .recovery-code-form {
    max-width: 430px;
  }
}

@media (min-width: $desktop-start) {
  .recovery-code-form {
    max-width: 325px;

    &__info {
      margin-bottom: 36px;
    }
  }

  .support-message {
    &__paragraph:first-child {
      width: 240px;
      margin-bottom: 14px;
    }
  }
}
</style>
