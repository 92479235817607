<template>
  <start-page-template compact>
    <recovery-code-form
      :authentication-error="formError"
      :loading="isLoading"
      @submit-code="onAuthenticate"
    />
  </start-page-template>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import StartPageTemplate from '@/components/templates/StartPageTemplate';
import RecoveryCodeForm from '@/components/start-page/RecoveryCodeForm';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import { hasResponseErrors } from '@/utils/storeActionsUtils';

export default {
  name: 'RecoveryCodePage',
  components: { StartPageTemplate, RecoveryCodeForm },
  props: {
    username: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: false,
      formError: ''
    };
  },
  mounted() {
    if (!this.username && !this.password) {
      this.$router.push({ name: 'Home' });
    }
  },
  methods: {
    ...mapMutations({
      setIsDashboardLocked: dashTypes.mutations.SET_IS_DASHBOARD_LOCKED
    }),
    ...mapActions({
      login: rootTypes.actions.LOGIN
    }),
    async onAuthenticate(code) {
      this.clearError();
      this.setLoading(true);

      const response = await this.login({
        username: this.username,
        password: this.password,
        recoveryCode: code
      });

      this.setLoading(false);

      if (hasResponseErrors(response)) {
        this.formError = this.$t('dashboard.authentication.codeVerificationFailed');

        return;
      }

      this.redirectToNextPage();
    },
    redirectToNextPage() {
      this.setIsDashboardLocked(false);

      if (this.pdfSummary) {
        this.$router.push({
          name: 'PatientCard',
          params: {
            id: this.pdfSummary.patientId
          }
        });

        return;
      }

      this.$router.push({ name: 'Dashboard' });
    },
    setLoading(value) {
      this.isLoading = value;
    },
    clearError() {
      this.formError = '';
    }
  }
};
</script>
